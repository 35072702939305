<template>
  <div id="container-options-menu" class="mt-5">
    <principalMenuItem v-for="item in items"
      :key="item.title"
      :title="$t(item.title)"
      :description="$t(item.description)"
      :route="item.route"
      :img="item.img"
    ></principalMenuItem>
  </div>
</template>
<script>
import principalMenuItem from '@core/spore-components/principalMenuItem/principalMenuItem.vue'

export default {
  components: {
    principalMenuItem
  },
  data () {
    return {
      items: [
        {
          route: '/tracker',
          img: 'pointtracker',
          title: 'Labels.Tracker',
          description: 'Labels.Tracker'
        },
        {
          route: '/panic_button/sm',
          img: 'panicbutton',
          title: 'Labels.ServiceButton',
          description: 'Labels.ServiceButton'
        }

      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Menu.scss";
</style>
