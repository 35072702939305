<template>
  <b-button class="button-option" :to="{ path: route }">
    <b-card >
      <div class="verticle-image-container">
        <img :src="getImgUrl(img)" alt="icon menu">
        <span class="verticle-title">{{title}}</span>
        <span class="verticle-description">{{description}}</span>
      </div>
    </b-card>
  </b-button>
</template>
<script>
import {
  BCard,
  BButton
} from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BButton
  },
  props: {
    route: String,
    img: String,
    title: String,
    description: String
  },
  methods: {
    getImgUrl (name) {
      const images = require.context('@/assets/images/menu/', false, /\.svg$/)
      return images(`./${name}.svg`)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/principalMenuItem.scss";
</style>
